<template>
  <div class="details content-index" v-loading="loading">
    <div>
      <el-page-header @back="goBack" content="衣物盘点详情"></el-page-header>
    </div>
    <div class="top-box">
      <div class="box-left">
        <div class="left-box1">
          <el-button disabled>已完成</el-button>
          <span class="icon"></span>
        </div>
        <div class="left-box2">日常盘点单：{{ checkData.checkNumber }}</div>
      </div>
      <div class="box-right">
        <div class="right-box1">盘点人: {{ checkData.storeUserName }}</div>
        <div class="right-box2">盘点时间: {{ checkData.createdTime }}</div>
      </div>
    </div>
    <div class="content">
      <el-card shadow="never">
        <div class="content-title">衣物明细</div>
        <div class="content-text">
          生成盘点衣物数据<b style="padding:0 2px;font-size:16px">{{
            checkData.createNum ? checkData.createNum : 0
          }}</b
          >件，实际扫码衣物<b style="padding:0 2px;font-size:16px">{{
            checkData.checkNum ? checkData.checkNum : 0
          }}</b
          >件，盘少<b style="padding:0 2px;font-size:16px">{{
            checkData.checkLessNum ? checkData.checkLessNum : 0
          }}</b
          >件，盘多<b style="padding:0 2px;font-size:16px">{{
            checkData.checkMoreNum ? checkData.checkMoreNum : 0
          }}</b
          >件，共计异常衣物<b style="padding:0 2px;font-size:16px">{{
            checkData.exceptionNum ? checkData.exceptionNum : 0
          }}</b
          >件
        </div>
        <el-tabs type="card" v-model="formData.flag" @tab-click="handleClick">
          <el-tab-pane label="全部" name="0">
            <div class="table-box" v-if="formData.flag == '0'">
              <Table
                :serialNumber="true"
                :current-value="formData.current"
                :header-style="headerStyle"
                :orientation="'center'"
                :table-data="tableList"
                :title-list="titleListData"
                :total="total"
                @handleCurrentChange="handleCurrentChange"
              />
            </div>
          </el-tab-pane>
          <el-tab-pane label="盘多" name="1">
            <div class="table-box" v-if="formData.flag == '1'">
              <Table
                :serialNumber="true"
                :current-value="formData.current"
                :header-style="headerStyle"
                :orientation="'center'"
                :table-data="tableList"
                :title-list="titleListData"
                :total="total"
                @handleCurrentChange="handleCurrentChange"
              />
            </div>
          </el-tab-pane>
          <el-tab-pane label="盘少" name="2">
            <div class="table-box" v-if="formData.flag == '2'">
              <Table
                :serialNumber="true"
                :current-value="formData.current"
                :header-style="headerStyle"
                :orientation="'center'"
                :table-data="tableList"
                :title-list="titleListData"
                :total="total"
                @handleCurrentChange="handleCurrentChange"
              />
            </div>
          </el-tab-pane>
          <el-tab-pane label="盘平" name="3">
            <div class="table-box" v-if="formData.flag == '3'">
              <Table
                :serialNumber="true"
                :current-value="formData.current"
                :header-style="headerStyle"
                :orientation="'center'"
                :table-data="tableList"
                :title-list="titleListData"
                :total="total"
                @handleCurrentChange="handleCurrentChange"
              />
            </div>
          </el-tab-pane>
          <el-tab-pane label="异常" name="4">
            <div class="table-box" v-if="formData.flag == '4'">
              <Table
                :serialNumber="true"
                :current-value="formData.current"
                :header-style="headerStyle"
                :orientation="'center'"
                :table-data="tableList"
                :title-list="titleListData2"
                :total="total"
                @handleCurrentChange="handleCurrentChange"
              />
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
  </div>
</template>

<script>
import Table from "@/components/table2";
import { getCheckDetail } from "@/api/clothes/check";
export default {
  components: {
    Table,
  },
  data() {
    return {
      formData: {
        current: 1,
        size: 10,
        flag: "0",
      },
      headerStyle: {
        fontSize: "14px",
        color: "black",
      },
      titleListData: [
        {
          prop: "orderNumber",
          label: "订单号",
        },
        {
          prop: "outClothesNum",
          label: "衣物号",
        },
        {
          prop: "clothesName",
          label: "衣物名称",
        },
        {
          prop: "colorName",
          label: "衣物颜色",
        },
        {
          prop: "clothesStatusDesc",
          label: "当前状态",
        },
        {
          prop: "sendWashTime",
          label: "送洗时间",
        },
        {
          prop: "joinFactoryTime",
          label: "入厂时间",
        },
        {
          prop: "outFactoryTime",
          label: "出厂时间",
        },
        {
          prop: "joinStoreTime",
          label: "入店时间",
        },
        {
          prop: "hangUpTime",
          label: "上挂时间",
        },
      ],
      titleListData2: [
        {
          prop: "orderNumber",
          label: "订单号",
        },
        {
          prop: "outClothesNum",
          label: "衣物号",
        },
        {
          prop: "clothesName",
          label: "衣物名称",
        },
        {
          prop: "colorName",
          label: "衣物颜色",
        },
        {
          prop: "clothesStatusDesc",
          label: "当前状态",
        },
        {
          prop: "sendWashTime",
          label: "送洗时间",
        },
        {
          prop: "joinFactoryTime",
          label: "入厂时间",
        },
        {
          prop: "outFactoryTime",
          label: "出厂时间",
        },
        {
          prop: "joinStoreTime",
          label: "入店时间",
        },
        {
          prop: "hangUpTime",
          label: "上挂时间",
        },
        {
          prop: "exceptionTypeDesc",
          label: "异常类型",
        },
        {
          prop: "updateStatusDesc",
          label: "修改状态",
        },
        {
          prop: "markFlagDesc",
          label: "标记内容",
        },
        {
          prop: "specialDescription",
          label: "特殊说明",
        },
      ],
      tableList: [],
      checkId: null,
      checkData: {},
      total: null,
      loading: false,
    };
  },
  watch: {
    $route: {
      handler: function(value) {
        this.checkId = value.query.checkId ? value.query.checkId : null;
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getCheckDetailList();
  },
  methods: {
    goBack() {
      this.$router.push("/home/more/clothinginventory");
    },
    // 获取盘点详情
    getCheckDetailList() {
      this.loading = true;
      getCheckDetail({
        checkId: this.checkId,
        ...this.formData,
      })
        .then(({ data }) => {
          if (data.code == 0) {
            this.loading = false;
            this.checkData = data.data;
            this.tableList = data.data.resPage?.records;
            this.total = data.data.resPage?.total;
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 点击标签页触发
    handleClick() {
      this.formData.current = 1;
      this.getCheckDetailList();
    },
    // 修改页码触发
    handleCurrentChange(val) {
      this.formData.current = val;
      this.getCheckDetailList();
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  padding: 20px;
  .top-box {
    display: flex;
    padding: 20px 0;
    justify-content: space-between;
    .box-left,
    .box-right {
      display: flex;
      align-items: center;
      font-size: 14px;
      .left-box1 {
        margin-right: 20px;
        position: relative;
        .el-button {
          color: #fff;
          background-color: #a5a9b1;
          padding-right: 40px;
        }
        .icon {
          position: absolute;
          top: 0;
          right: 0;
          height: 0;
          width: 0;
          border: 20px solid;
        //   border-right-width: 30px;
          border-top-color: transparent;
          border-bottom-color: transparent;
          border-left-color: transparent;
          border-right-color: #fff;
        }
      }
      .right-box2 {
        margin: 0 40px;
      }
    }
  }
  .content {
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    ::v-deep .el-card__body {
      padding: 15px;
    }
    .content-title {
      font-size: 16px;
      font-weight: 700;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(235, 237, 240, 100);
    }
    .content-text {
      padding: 12px 0;
    }
    .table-box {
      height: calc(100vh - 420px);
    }
    .el-tabs {
      ::v-deep .el-tabs__header {
        margin-bottom: 10px;
      }
      //   ::v-deep .el-tabs__nav {
      //     background-color: #e4e7ed;
      //   }
      //   ::v-deep  .el-tabs__item.is-active{
      //     background-color: #fff;
      //   }
    }
  }
}
</style>
